import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const oemsPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Oems {
                  templateName
                  oemsRichContent {
                    #   SECTION: Hero
                    hero {
                      header
                      text
                      buttons {
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                      logo {
                        ...wpImage
                      }
                      image {
                        altText
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                          childSvg {
                            content {
                              data
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Trade Lifecycle
                    tradeLifecycle {
                      tabsSection {
                        header
                        text
                        slug
                        image {
                          ...wpImageFluid
                        }
                        tabs {
                          label
                          content {
                            ... on WpTemplate_Oems_Oemsrichcontent_TradeLifecycle_TabsSection_tabs_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                url
                                title
                                target
                              }
                            }
                            ... on WpTemplate_Oems_Oemsrichcontent_TradeLifecycle_TabsSection_tabs_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                              items {
                                text
                              }
                            }
                            ... on WpTemplate_Oems_Oemsrichcontent_TradeLifecycle_TabsSection_tabs_Content_Accordion {
                              fieldGroupName
                              header
                              content {
                                ... on WpTemplate_Oems_Oemsrichcontent_TradeLifecycle_TabsSection_tabs_Content_Accordion_Content_Text {
                                  fieldGroupName
                                  header
                                  text
                                  link {
                                    target
                                    title
                                    url
                                  }
                                }
                                ... on WpTemplate_Oems_Oemsrichcontent_TradeLifecycle_TabsSection_tabs_Content_Accordion_Content_List {
                                  fieldGroupName
                                  header
                                  textAbove
                                  textBelow
                                  items {
                                    text
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Product Mockup Image
                    productMockupImage {
                      imageSection {
                        text
                        title
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                      }
                    }

                    # SECTION: Connectivity
                    connectivity {
                      header
                      text
                      slug
                    }

                    # SECTION: Features
                    features {
                      header
                      text
                      button {
                        target
                        title
                        url
                      }
                      topList {
                        header
                        text
                      }
                      iconsList {
                        header
                        text
                        icon {
                          ...wpImage
                        }
                      }
                    }
                    #
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "oemsRichContent");
};

export { oemsPageTplData };
